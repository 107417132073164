<template>
  <main class="provably-fair-layout">
    <HeaderControllerSkeleton />
    <TopLiveDropControllerSkeleton />
    <section class="provably-fair-layout__content">
      <header v-if="!route.meta.hideMenu" class="provably-fair-layout__content-header">
        <PFTitleController />
        <PFNavigationController />
      </header>
      <slot />
    </section>
  </main>
</template>

<script setup lang="ts">
const route = useRoute();
</script>

<style scoped lang="scss">
@use 'assets/styles/media';

.provably-fair-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  background: var(--main-color-900);

  &__content {
    --top-offset: var(--gap-lm);
    --bg-image: url('~/assets/images/ProvablyFair/bg-md.png');
    --bg-position: 0;
    --gap: var(--gap-lm);

    display: flex;
    flex: 1;
    flex-direction: column;
    gap: var(--gap);
    align-items: center;
    padding-top: var(--top-offset);
    background-image: var(--bg-image);
    background-repeat: no-repeat;
    background-position-y: var(--bg-position);
    background-size: 100%;

    @include media.media-breakpoint-up(md) {
      --top-offset: var(--gap-l);
    }

    @include media.media-breakpoint-up(xl) {
      --top-offset: var(--gap-xl);
      --bg-image: url('~/assets/images/ProvablyFair/bg-xl.png');
      --bg-position: -76px;
      --gap: 36px;
    }

    &-header {
      display: flex;
      flex-direction: column;
      gap: var(--gap);
      align-items: center;
      max-width: 328px;

      @include media.media-breakpoint-up(md) {
        max-width: 664px;
      }

      @include media.media-breakpoint-up(xl) {
        max-width: 840px;
      }
    }
  }
}
</style>
