<template>
  <div class="navigation">
    <div v-if="!isAuth" class="navigation__unauth">
      <p class="navigation__unauth-text">{{ $t('provablyFair.authNeeded') }}</p>
      <GeneralAuthButtonController :amplitude-source-of-click="EAuthButtonPlaceClicked.PROVABLY_FAIR" />
    </div>
    <div v-else class="navigation__auth">
      <NuxtLinkLocale
        v-for="link in links"
        :key="link.name"
        :to="link.to"
        class="navigation__auth-link"
        exact-active-class="navigation__auth-link--active"
      >
        {{ link.name }}
      </NuxtLinkLocale>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '~/features/authentication/store/authorization';
import { EAuthButtonPlaceClicked } from '~/repository/amplitude/types/auth.types';

const { t } = useI18n();

const authStore = useAuthStore();
const { isAuth } = storeToRefs(authStore);

const links = [
  {
    name: t('provablyFair.seedSettings'),
    to: ROUTING.PROVABLY_FAIR.SETTINGS,
  },
  {
    name: t('provablyFair.fairCheck'),
    to: ROUTING.PROVABLY_FAIR.CHECK,
  },
  {
    name: t('provablyFair.instructions.title'),
    to: ROUTING.PROVABLY_FAIR.INSTRUCTIONS,
  },
];
</script>

<style scoped lang="scss" src="./NavigationController.scss"></style>
